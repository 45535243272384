<template>
 <img src="@/assets/images/jy.jpg" alt="" style="width: 100%;" @click="picclick">
</template>

<script>
import axios from "axios";
import {getactivitytype , getactivswiper ,getactivitylist,getactivitylisttype} from "@/api"
export default {
  name: "activity",
  data() {
    return {
     
    };
  },
  methods: {
    start(){
      
    },
    picclick(){
      window.location.href = 'https://m.siem-icmlab.ac.cn/'
    },
  mounted(){
    this.start()
  }
  }
};
</script>

<style lang="less" scoped>

</style>